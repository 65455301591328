import React from "react";

import { Link, graphql } from "gatsby";
import IRLayout from "../components/Layout";

import Img from "gatsby-image";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
const TestPage = ({ data }) => (
  <IRLayout>
    <div className="section white" style={{ padding: "0px", margin: "0px" }}>
      <img
        src="https://buildersbay.imgix.net/wp-content/uploads/2019/05/31nUm3neTrL1.jpg?w=363&h=303&fit=crop&crop=edges&auto=compress"
        style={{ width: "100%" }}
      />
      <Container>
        <Row className="my-row">
          <Col xs={12} md={12} className="home_hero_text">
            <h2
              style={{
                marginTop: "24px",
                marginBottom: "0px",
                fontWeight: "900"
              }}
            >
              Electrolux EOC5440AAX Built-In Single Oven
            </h2>
            <h3
              style={{
                marginTop: "16px",
                marginBottom: "16px",
                fontWeight: "500",
                fontSize: "1.2em"
              }}
            >
              £255{" "}
              <span
                style={{
                  color: "#3399ff",
                  fontSize: "0.8em",
                  marginLeft: "8px"
                }}
              >
                you save £80
              </span>
            </h3>
            <Button variant="primary" style={{ width: "100%" }}>
              Checkout
            </Button>

            <p className="lead">
              Hourly gives you access to top flight consultants with a proven
              track record in startup consulting, tech, investment and
              recruiting and we do it by the hour. No costly spin up, no long
              term commitments and no 6 figure budgets.
            </p>
            <p className="lead">Expert help exactly when you need it.</p>
            <p>
              <Link to={`/consultants/`}>View our consultants</Link>
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  </IRLayout>
);

export default TestPage;
